<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >

        {{ $t('create_new') }}
        <v-icon right >
          mdi-chevron-down
        </v-icon>

      </v-btn>
    </template>

    <v-list dense >
      <template v-for="(item, index) in actionableOptions">

        <v-list-item
          :key="index"
          v-if="item.type == 'action' && isActionAvailable(item.action_key)"
          @click="$emit(item.event, item.eventArg)"
          link
          ripple

        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" :color="item.color || 'defaultIconColor'"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t(item.title)" class="menu-item"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider :key="index" v-else-if="isActionAvailable(item.action_key)" />

      </template>
    </v-list>

  </v-menu>
</template>

<script>

export default {
  name: 'UploadButton',

  props: {
    availableActions: {
      type: Array
    }
  },

  data() {
    return {
      actionableOptions: [
        {
          title: "create_folder",
          action_key: 'upload',
          event: 'create-folder',
          icon: 'mdi-folder-plus-outline',
          type: 'action'
        },
        {
          type: 'divider',
          action_key: 'upload',
        },
        {
          title: "file_upload",
          action_key: 'upload',
          event: 'file-upload',
          icon: 'mdi-file-upload-outline',
          type: 'action'
        },
        {
          type: 'divider',
          action_key: 'create_gdocs',
        },
        {
          title: "google_document",
          action_key: 'create_gdocs',
          event: 'create-google-doc',
          eventArg: "application/vnd.google-apps.document",
          icon: 'mdi-text-box',
          color: '#4285F4',
          type: 'action'
        },
        {
          title: "google_sheet",
          action_key: 'create_gdocs',
          event: 'create-google-doc',
          eventArg: "application/vnd.google-apps.spreadsheet",
          icon: 'mdi-google-spreadsheet',
          color: '#0F9D58',
          type: 'action'
        },
        {
          title: "google_presentation",
          action_key: 'create_gdocs',
          event: 'create-google-doc',
          eventArg: "application/vnd.google-apps.presentation",
          icon: 'mdi-file-presentation-box',
          color: '#F4B400',
          type: 'action'
        },
        {
          title: "google_colab",
          action_key: 'create_gdocs',
          event: 'create-google-doc',
          eventArg: "application/vnd.google.colaboratory",
          icon: 'mdi-language-python',
          color: '#FFD43B',
          type: 'action'
        },
        {
          type: 'divider',
          action_key: 'create_synthetic_media',
        },
        {
          title: "synthetic_media",
          action_key: 'create_synthetic_media',
          event: 'create-synthetic-media',
          icon: 'mdi-star-four-points-box',
          color: '#4cab3d',
          type: 'action'
        },
        {
          title: "self_service_template",
          action_key: 'create_self_service_template',
          event: 'create-self-service-template',
          icon: 'mdi-image-multiple-outline',
          color: '#129EAF',
          type: 'action'
        },

      ],
    }
  },

  methods: {
    isActionAvailable(action) {
      return this.availableActions.some(a => a.action_key == action)
    },
  },
}

</script>

<style scoped>
  .v-list-item__title {
    font-size: 0.9rem !important;
    padding-right: 0.9rem;
  }
</style>